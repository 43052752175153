import { Units } from '@turf/turf';

import { ReportImpact, ReportTypeCategory } from '@/core/interfaces/common';
import { AppliedFilters, AutoDateRange, KeywordsFilters } from '@/core/interfaces/filters';
import { GEOJSONData, GEOJSONShapeType } from '@/core/interfaces/geojsons';
import { IconPickerIcons } from '@/core/constants/icons';

export enum FocusDataStream {
  HORIZON = 'Horizon',
  LOOKOUT = 'Lookout',
}

export enum NotificationsTimeOptions {
  REALTIME = 'Real-Time',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
}

export enum FocusNotificationDayOfWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export interface EmailFrequencyType {
  frequency?: NotificationsTimeOptions | null;
  hour?: string | null;
  day?: FocusNotificationDayOfWeek | null;
}

export interface FocusType {
  id: number;
  name: string;
  description?: string;
  filters?: AppliedFilters;
  emailNotifications?: boolean;
  emailFrequency?: EmailFrequencyType;
  coworkers?: Array<string>;
  users: Array<string>;
}

export type FocusGeofenceItem = {
  uuid: string;
  name: string;
  description: string;
  icon: IconPickerIcons;
  color: string;
  tags: Array<string>;
  shape: GEOJSONShapeType;
  address: string | null;
  radius: number | null;
  radiusConverted: number | null;
  radiusUnit: Units | null;
  long: number | null;
  lat: number | null;
  polygon: {
    type: 'Polygon';
    coordinates: Array<Array<Array<number>>>;
  } | null;
  point: {
    type: 'Point';
    coordinates: [number, number];
  } | null;
  line: {
    type: 'LineString';
    coordinates: Array<Array<number>>;
  } | null;
};

export type CreateFocusPayload = {
  name: string;
  description?: string;
  publishedSinceSeconds: number | null;
  autoDateRange: AutoDateRange | null;
  eventStartDate: string | null;
  eventEndDate: string | null;
  impact?: Array<ReportImpact> | null;
  datastream?: Array<FocusDataStream>;
  reportType?: Array<ReportTypeCategory>;
  keywords?: KeywordsFilters['keywords'] | null;
  categories?: Array<number>;
  countries?: Array<number>;
  users?: Array<string>;
  organizations?: Array<string>;
  folders?: Array<number>;
  geofences?: Array<GEOJSONData>;
};

export type FocusResponseItem = Required<
  Omit<CreateFocusPayload, 'geofences' | 'categories' | 'countries'>
> & {
  id: number;
  geofences: Array<FocusGeofenceItem>;
  categories: Array<string | number>;
  countries: Array<string | number>;
};

export type CreateFocusAlertPayload = {
  receiveInApp?: boolean;
  receiveMobileApp?: boolean;
  receiveEmail: boolean;
  notificationPreference: NotificationsTimeOptions | null;
  timeOfNotification: string | null;
  dayOfTheWeek: FocusNotificationDayOfWeek | null;
};

export type UpdateFocusAlertPayload = CreateFocusAlertPayload;

export type FocusAlertResponse = {
  receiveInApp?: boolean;
  receiveMobileApp?: boolean;
  receiveEmail: boolean;
  notificationPreference: NotificationsTimeOptions | null;
  timeOfNotification: string | null;
  dayOfTheWeek: FocusNotificationDayOfWeek | null;
};

export type FocusAlertItem = FocusAlertResponse & {
  id?: number;
  focus: number;
};

export type CreateFocusData = CreateFocusPayload & CreateFocusAlertPayload;

export type UpdateFocusData = CreateFocusData;
