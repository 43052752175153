import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GEOJSONData,
  GEOJSONShapeType,
  LineGEOJSONData,
  PointOfInterestGEOJSONData,
  PolygonGEOJSONData,
  RadialGEOJSONData,
} from '@/core/interfaces/geojsons';
import { ExcludesEmpty } from '@/core/interfaces/common';
import { getCategoriesSelector, getCountriesSelector } from '@/core/store/reducers/config';
import { CategoriesResponse } from '@/core/interfaces/categories';
import { CountriesResponse } from '@/core/interfaces/countries';
import { RootState } from '@/core/interfaces/store';

import {
  CreateFocusAlertPayload,
  CreateFocusData,
  CreateLineGeofenceData,
  CreateLineGeofenceResponse,
  CreatePointOfInterestGeofenceData,
  CreatePointOfInterestGeofenceResponse,
  CreatePolygonGeofenceData,
  CreatePolygonGeofenceResponse,
  CreateRadialGeofenceData,
  CreateRadialGeofenceResponse,
  FocusAlertItem,
  FocusResponseItem,
  UpdateFocusData,
} from '@/features/Focus/interfaces';
import { api } from '@/features/Focus/api';

import { getActionPrefix } from '@/utils/helpers';

import { getSelectedFocusSelector } from './slices';

const actionPrefix = getActionPrefix('focuses');

export const deleteFocus = createAsyncThunk(`${actionPrefix}/deleteFocus`, async (id: number) => {
  await api.deleteFocus(id);

  return id;
});

const createRadialGeofence = createAsyncThunk(
  `${actionPrefix}/createRadialGeofence`,
  async (geofenceData: CreateRadialGeofenceData) => {
    const { data } = await api.createRadialGeofence(geofenceData);

    return data;
  }
);

const createPolygonGeofence = createAsyncThunk(
  `${actionPrefix}/createPolygonGeofence`,
  async (geofenceData: CreatePolygonGeofenceData) => {
    const { data } = await api.createPolygonGeofence(geofenceData);

    return data;
  }
);

const createLineGeofence = createAsyncThunk(
  `${actionPrefix}/createLineGeofence`,
  async (geofenceData: CreateLineGeofenceData) => {
    const { data } = await api.createLineGeofence(geofenceData);

    return data;
  }
);

const createPointOfInterestGeofence = createAsyncThunk(
  `${actionPrefix}/createPointOfInterestGeofence`,
  async (geofenceData: CreatePointOfInterestGeofenceData) => {
    const { data } = await api.createPointOfInterestGeofence(geofenceData);

    return data;
  }
);

const updateRadialGeofence = createAsyncThunk<
  CreateRadialGeofenceResponse,
  {
    id: string;
    geofenceData: CreateRadialGeofenceData;
  }
>(`${actionPrefix}/updateRadialGeofence`, async ({ id, geofenceData }) => {
  const { data } = await api.updateRadialGeofence(id, geofenceData);

  return data;
});

const updatePolygonGeofence = createAsyncThunk<
  CreatePolygonGeofenceResponse,
  {
    id: string;
    geofenceData: CreatePolygonGeofenceData;
  }
>(`${actionPrefix}/updatePolygonGeofence`, async ({ id, geofenceData }) => {
  const { data } = await api.updatePolygonGeofence(id, geofenceData);

  return data;
});

const updateLineGeofence = createAsyncThunk<
  CreateLineGeofenceResponse,
  {
    id: string;
    geofenceData: CreateLineGeofenceData;
  }
>(`${actionPrefix}/updateLineGeofence`, async ({ id, geofenceData }) => {
  const { data } = await api.updateLineGeofence(id, geofenceData);

  return data;
});

const updatePointOfInterestGeofence = createAsyncThunk<
  CreatePointOfInterestGeofenceResponse,
  {
    id: string;
    geofenceData: CreatePointOfInterestGeofenceData;
  }
>(`${actionPrefix}/updatePointOfInterestGeofence`, async ({ id, geofenceData }) => {
  const { data } = await api.updatePointOfInterestGeofence(id, geofenceData);

  return data;
});

const deleteFocusGeofence = createAsyncThunk(
  `${actionPrefix}/deleteFocusGeofence`,
  async (id: string) => {
    await api.deleteFocusGeofence(id);

    return id;
  }
);

const getGeofenceAction = (
  geofence: GEOJSONData,
  focusId: number
): {
  createActionFunction:
    | typeof createRadialGeofence
    | typeof createPolygonGeofence
    | typeof createLineGeofence
    | typeof createPointOfInterestGeofence;
  updateActionFunction:
    | typeof updateRadialGeofence
    | typeof updatePolygonGeofence
    | typeof updateLineGeofence
    | typeof updatePointOfInterestGeofence;
  actionData:
    | CreateRadialGeofenceData
    | CreatePolygonGeofenceData
    | CreateLineGeofenceData
    | CreatePointOfInterestGeofenceData;
} => {
  switch (geofence.properties.shape) {
    case GEOJSONShapeType.CIRCLE: {
      const circleGeofence = geofence as RadialGEOJSONData;

      return {
        createActionFunction: createRadialGeofence,
        updateActionFunction: updateRadialGeofence,
        actionData: {
          ...circleGeofence,
          properties: {
            ...circleGeofence.properties,
            focus: focusId,
            radiusConverted: 0,
          },
        },
      };
    }
    case GEOJSONShapeType.POLYGON: {
      const polygonGeofence = geofence as PolygonGEOJSONData;

      return {
        createActionFunction: createPolygonGeofence,
        updateActionFunction: updatePolygonGeofence,
        actionData: {
          ...polygonGeofence,
          properties: {
            ...polygonGeofence.properties,
            focus: focusId,
          },
        },
      };
    }
    case GEOJSONShapeType.LINE: {
      const lineGeofence = geofence as LineGEOJSONData;

      return {
        createActionFunction: createLineGeofence,
        updateActionFunction: updateLineGeofence,
        actionData: {
          ...lineGeofence,
          properties: {
            ...lineGeofence.properties,
            focus: focusId,
          },
        },
      };
    }
    case GEOJSONShapeType.POI: {
      const pointOfInterestGeofence = geofence as PointOfInterestGEOJSONData;

      return {
        createActionFunction: createPointOfInterestGeofence,
        updateActionFunction: updatePointOfInterestGeofence,
        actionData: {
          ...pointOfInterestGeofence,
          properties: {
            ...pointOfInterestGeofence.properties,
            focus: focusId,
            radius: pointOfInterestGeofence.properties.radius!,
            radiusConverted: 0,
            radiusUnit:
              // @ts-expect-error radius_unit might be passed after changing case
              pointOfInterestGeofence.properties.radius_unit ||
              pointOfInterestGeofence.properties.radiusUnit,
          },
        },
      };
    }
  }
};

export const createFocus = createAsyncThunk<
  {
    focusData: FocusResponseItem;
    alerts: Array<FocusAlertItem>;
    categories: CategoriesResponse;
    countries: CountriesResponse;
  },
  CreateFocusData,
  {
    rejectValue: { message: string };
  }
>(`${actionPrefix}/createFocus`, async (focusData, { dispatch, rejectWithValue, getState }) => {
  const state = getState() as RootState;
  const categoriesList = getCategoriesSelector(state);
  const countriesList = getCountriesSelector(state);
  const {
    geofences,
    receiveInApp,
    receiveMobileApp,
    receiveEmail,
    notificationPreference,
    timeOfNotification,
    dayOfTheWeek,
    ...restFocusData
  } = focusData;
  const { data } = await api.createFocus(restFocusData);

  let focusAlertData: FocusAlertItem | null = null;

  if (receiveEmail) {
    const { data: alertData } = await api.createFocusAlert(data.id, {
      receiveInApp,
      receiveMobileApp,
      receiveEmail,
      notificationPreference,
      timeOfNotification,
      dayOfTheWeek,
    });

    focusAlertData = alertData;
  }

  const geofencesPromises: Array<
    Promise<
      | CreateRadialGeofenceResponse
      | CreatePolygonGeofenceResponse
      | CreateLineGeofenceResponse
      | CreatePointOfInterestGeofenceResponse
    >
  > =
    geofences
      ?.map(geofence => {
        const { createActionFunction, actionData } = getGeofenceAction(geofence, data.id);

        if (!createActionFunction) {
          return undefined;
        }

        // @ts-expect-error createActionFunction is a thunk action
        return dispatch(createActionFunction(actionData)).unwrap();
      })
      .filter(Boolean as unknown as ExcludesEmpty) || [];

  let createdGeofences: Array<
    | CreateRadialGeofenceResponse
    | CreatePolygonGeofenceResponse
    | CreateLineGeofenceResponse
    | CreatePointOfInterestGeofenceResponse
  > = [];

  try {
    createdGeofences = await Promise.all(geofencesPromises);
  } catch (error) {
    await dispatch(deleteFocus(data.id)).unwrap();

    return rejectWithValue({ message: 'Error creating geofences' });
  }

  const focus: FocusResponseItem = {
    ...data,
    geofences: createdGeofences.map(geofence => ({
      // @ts-expect-error address exists for radial and poi geofences
      address: geofence.properties.address || null,
      color: geofence.properties.color,
      description: geofence.properties.description,
      icon: geofence!.properties.icon,
      name: geofence!.properties.name,
      uuid: geofence!.id,
      shape: geofence.properties.shape,
      tags: geofence.properties.tags,
      // @ts-expect-error lat exists for radial geofences
      lat: geofence.properties.lat || null,
      // @ts-expect-error lng exists for radial geofences
      long: geofence.properties.lng || null,
      // @ts-expect-error radius exists for radial geofences and poi geofences
      radius: geofence.properties.radius || null,
      radiusConverted: 0,
      // @ts-expect-error radius exists for radial geofences and poi geofences
      radiusUnit: geofence.properties.radiusUnit || null,
      polygon: [GEOJSONShapeType.CIRCLE, GEOJSONShapeType.POLYGON].includes(
        geofence.properties.shape
      )
        ? {
            type: 'Polygon',
            coordinates: (
              geofence.geometry as RadialGEOJSONData['geometry'] | PolygonGEOJSONData['geometry']
            ).coordinates,
          }
        : null,
      point:
        GEOJSONShapeType.POI === geofence.properties.shape
          ? {
              type: 'Point',
              coordinates: (geofence.geometry as PointOfInterestGEOJSONData['geometry'])
                .coordinates,
            }
          : null,
      line:
        GEOJSONShapeType.LINE === geofence.properties.shape
          ? {
              type: 'LineString',
              coordinates: (geofence.geometry as LineGEOJSONData['geometry']).coordinates,
            }
          : null,
    })),
  };

  return {
    focusData: focus,
    alerts: focusAlertData ? [focusAlertData] : [],
    categories: categoriesList,
    countries: countriesList,
  };
});

export const updateFocus = createAsyncThunk<
  {
    focusData: FocusResponseItem;
    alerts: Array<FocusAlertItem>;
    categories: CategoriesResponse;
    countries: CountriesResponse;
  },
  {
    id: number;
    focusData: UpdateFocusData;
    geofencesToCreate: Array<GEOJSONData>;
    geofencesToUpdate: Array<GEOJSONData>;
    geofencesToDelete: Array<string>;
  }
>(
  `${actionPrefix}/updateFocus`,
  async (
    { id, focusData, geofencesToCreate, geofencesToUpdate, geofencesToDelete },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const categoriesList = getCategoriesSelector(state);
    const countriesList = getCountriesSelector(state);
    const originalFocus = getSelectedFocusSelector(state);
    const {
      geofences: _geofences,
      receiveInApp,
      receiveMobileApp,
      receiveEmail,
      notificationPreference,
      timeOfNotification,
      dayOfTheWeek,
      ...restFocusData
    } = focusData;
    const { data } = await api.updateFocus(id, restFocusData);

    let focusAlertData: FocusAlertItem | null = null;

    if (receiveEmail && originalFocus?.emailNotifications) {
      const { data: alertData } = await api.updateFocusAlert(data.id, {
        receiveInApp,
        receiveMobileApp,
        receiveEmail,
        notificationPreference,
        timeOfNotification,
        dayOfTheWeek,
      });

      focusAlertData = {
        ...alertData,
        focus: data.id,
      };
    } else if (receiveEmail && !originalFocus?.emailNotifications) {
      const { data: alertData } = await api.createFocusAlert(data.id, {
        receiveInApp,
        receiveMobileApp,
        receiveEmail,
        notificationPreference,
        timeOfNotification,
        dayOfTheWeek,
      });

      focusAlertData = alertData;
    } else if (!receiveEmail && originalFocus?.emailNotifications) {
      await api.deleteFocusAlert(data.id);
    }

    const geofencesToCreatePromises: Array<
      Promise<
        | CreateRadialGeofenceResponse
        | CreatePolygonGeofenceResponse
        | CreateLineGeofenceResponse
        | CreatePointOfInterestGeofenceResponse
      >
    > =
      geofencesToCreate
        .map(geofence => {
          const { createActionFunction, actionData } = getGeofenceAction(geofence, data.id);

          if (!createActionFunction) {
            return undefined;
          }

          // @ts-expect-error createActionFunction is a thunk action
          return dispatch(createActionFunction(actionData)).unwrap();
        })
        .filter(Boolean as unknown as ExcludesEmpty) || [];

    const geofencesToUpdatePromises: Array<
      Promise<
        | CreateRadialGeofenceResponse
        | CreatePolygonGeofenceResponse
        | CreateLineGeofenceResponse
        | CreatePointOfInterestGeofenceResponse
      >
    > =
      geofencesToUpdate
        .map(geofence => {
          const { updateActionFunction, actionData } = getGeofenceAction(geofence, data.id);

          if (!updateActionFunction) {
            return undefined;
          }

          return dispatch(
            // @ts-expect-error updateActionFunction is a thunk action
            updateActionFunction({
              id: geofence.properties.id,
              // @ts-expect-error actionData type is aligned with the updateActionFunction
              geofenceData: actionData,
            })
          ).unwrap();
        })
        .filter(Boolean as unknown as ExcludesEmpty) || [];

    const geofencesToDeletePromises = geofencesToDelete.map(geofenceId =>
      dispatch(deleteFocusGeofence(geofenceId)).unwrap()
    );

    const geofences = await Promise.all([
      ...geofencesToCreatePromises,
      ...geofencesToUpdatePromises,
    ]);

    await Promise.all(geofencesToDeletePromises);

    const focus: FocusResponseItem = {
      ...data,
      geofences: geofences.map(geofence => ({
        // @ts-expect-error address exists for radial and poi geofences
        address: geofence.properties.address || null,
        color: geofence.properties.color,
        description: geofence.properties.description,
        icon: geofence.properties.icon,
        name: geofence.properties.name,
        uuid: geofence.id,
        shape: geofence.properties.shape,
        tags: geofence.properties.tags,
        // @ts-expect-error lat exists for radial geofences
        lat: geofence.properties.lat || null,
        // @ts-expect-error lng exists for radial geofences
        long: geofence.properties.lng || null,
        // @ts-expect-error radius exists for radial geofences and poi geofences
        radius: geofence.properties.radius || null,
        radiusConverted: 0,
        // @ts-expect-error radius exists for radial geofences and poi geofences
        radiusUnit: geofence.properties.radiusUnit || null,
        polygon: [GEOJSONShapeType.CIRCLE, GEOJSONShapeType.POLYGON].includes(
          geofence.properties.shape
        )
          ? {
              type: 'Polygon',
              coordinates: (
                geofence.geometry as RadialGEOJSONData['geometry'] | PolygonGEOJSONData['geometry']
              ).coordinates,
            }
          : null,
        point:
          GEOJSONShapeType.POI === geofence.properties.shape
            ? {
                type: 'Point',
                coordinates: (geofence.geometry as PointOfInterestGEOJSONData['geometry'])
                  .coordinates,
              }
            : null,
        line:
          GEOJSONShapeType.LINE === geofence.properties.shape
            ? {
                type: 'LineString',
                coordinates: (geofence.geometry as LineGEOJSONData['geometry']).coordinates,
              }
            : null,
      })),
    };

    return {
      focusData: focus,
      alerts: focusAlertData ? [focusAlertData] : [],
      categories: categoriesList,
      countries: countriesList,
    };
  }
);

export const createFocusAlert = createAsyncThunk<
  FocusAlertItem,
  {
    focusId: number;
    alertData: CreateFocusAlertPayload;
  }
>(`${actionPrefix}/createFocusAlert`, async ({ focusId, alertData }) => {
  const { data } = await api.createFocusAlert(focusId, alertData);

  return {
    ...data,
    focus: focusId,
  };
});

export const updateFocusAlert = createAsyncThunk<
  FocusAlertItem,
  {
    focusId: number;
    alertData: CreateFocusAlertPayload;
  }
>(`${actionPrefix}/updateFocusAlert`, async ({ focusId, alertData }) => {
  const { data } = await api.updateFocusAlert(focusId, alertData);

  return {
    ...data,
    focus: focusId,
  };
});

export const deleteFocusAlert = createAsyncThunk(
  `${actionPrefix}/deleteFocusAlert`,
  async (focusId: number) => {
    await api.deleteFocusAlert(focusId);

    return focusId;
  }
);

export const getFocusAlert = createAsyncThunk<FocusAlertItem, number>(
  `${actionPrefix}/getFocusAlert`,
  async id => {
    const { data } = await api.getFocusAlert(id);

    return {
      ...data,
      focus: id,
    };
  }
);

export const getFocusesList = createAsyncThunk(
  `${actionPrefix}/getFocusesList`,
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const categoriesList = getCategoriesSelector(state);
    const countriesList = getCountriesSelector(state);
    const { data } = await api.getFocusesList();

    const alertsPromises = data.map(focus => dispatch(getFocusAlert(focus.id)).unwrap());

    let alerts: Array<FocusAlertItem> = [];

    try {
      const alertsResponses = await Promise.allSettled(alertsPromises);

      alerts = alertsResponses
        .filter(response => response.status === 'fulfilled')
        .map(response => (response as PromiseFulfilledResult<FocusAlertItem>).value);
    } catch (error) {
      console.log('error', error);
    }

    return {
      focuses: data,
      alerts,
      categories: categoriesList,
      countries: countriesList,
    };
  }
);

export const getSharedFocusesList = createAsyncThunk(
  `${actionPrefix}/getSharedFocusesList`,
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const categoriesList = getCategoriesSelector(state);
    const countriesList = getCountriesSelector(state);
    const { data } = await api.getSharedFocusesList();

    const alertsPromises = data.map(focus => dispatch(getFocusAlert(focus.id)).unwrap());

    let alerts: Array<FocusAlertItem> = [];

    try {
      const alertsResponses = await Promise.allSettled(alertsPromises);

      alerts = alertsResponses
        .filter(response => response.status === 'fulfilled')
        .map(response => (response as PromiseFulfilledResult<FocusAlertItem>).value);
    } catch (error) {
      console.log('error', error);
    }

    return {
      focuses: data,
      alerts,
      categories: categoriesList,
      countries: countriesList,
    };
  }
);
