import {
  getKeywordFilterLabel,
  getKeywordFilterValue,
} from '@/core/components/MapFilters/KeywordFilter/KeywordsFilter.utils';
import {
  LocationCountryFilterItemValue,
  LocationFilterItemType,
  LocationGeofenceFilterItemValue,
  LocationGeofenceFilterRadialItemValue,
  LocationPoiFilterItemValue,
} from '@/core/components/MapFilters/LocationFilter/LocationFilter.types';
import {
  dropdownTimeframeOptions,
  timeframeCustomTimeData,
  timeframeRadioButtonData,
} from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';
import { CategoriesResponse } from '@/core/interfaces/categories';
import { API_ACCESS, ExcludesEmpty } from '@/core/interfaces/common';
import { CountriesResponse } from '@/core/interfaces/countries';
import {
  AppliedFilterData,
  AppliedFilterDataItem,
  AppliedFilters,
  AutoDateRange,
  FilterType,
  KeywordsFilters,
} from '@/core/interfaces/filters';
import {
  BaseGEOJSONProperties,
  GEOJSONCollectionData,
  GEOJSONData,
  GEOJSONShapeType,
  LineGEOJSONData,
  PointOfInterestGEOJSONData,
  PolygonGEOJSONData,
  RadialGEOJSONData,
} from '@/core/interfaces/geojsons';

import {
  CreateFocusPayload,
  FocusDataStream,
  FocusGeofenceItem,
  FocusResponseItem,
} from '@/features/Focus/interfaces';

import { getLocationsFromAppliedFilters, transformAppliedFiltersToPostData } from '@/utils/filters';
import { decodeHtml, reverseGeoJSONCoordinates } from '@/utils/helpers';
import { fromBase64, getStringifiedLocationFilterData } from '@/utils/locations';

type AvailableDatastreams = FocusDataStream.HORIZON | FocusDataStream.LOOKOUT;

const getDatastreamsFromAppliedFilters = (moreFilters: AppliedFilters[FilterType.MORE_FILTERS]) => {
  return moreFilters
    ? [API_ACCESS.HORIZON, API_ACCESS.LOOKOUT].reduce((acc, datastreamValue) => {
        const datastream = moreFilters.find(
          ({ value: filterValue }) => filterValue === datastreamValue
        );

        if (datastream && datastream.value === API_ACCESS.LOOKOUT) {
          acc.push(FocusDataStream.LOOKOUT);
        }

        if (datastream && datastream.value === API_ACCESS.HORIZON) {
          acc.push(FocusDataStream.HORIZON);
        }

        return acc;
      }, [] as Array<AvailableDatastreams>)
    : undefined;
};

export const getGeofencesFromAppliedFilters = (
  locationFilters: AppliedFilters[FilterType.LOCATION]
) => {
  const { geofeatures } = getLocationsFromAppliedFilters(locationFilters);

  const geofencesList: Array<GEOJSONData> = [];

  if (geofeatures) {
    const geofeaturesData = JSON.parse(fromBase64(geofeatures)) as unknown as
      | GEOJSONData
      | GEOJSONCollectionData;

    if (geofeaturesData.type === 'FeatureCollection') {
      geofencesList.push(...geofeaturesData.features);
    } else {
      geofencesList.push(geofeaturesData);
    }
  }

  return geofencesList?.length ? geofencesList : undefined;
};

export const transformAppliedFiltersToFocusData = (
  filters: AppliedFilters
): Omit<
  CreateFocusPayload,
  | 'name'
  | 'description'
  | 'receiveInApp'
  | 'receiveEmail'
  | 'receiveMobileApp'
  | 'user'
  | 'organization'
  | 'emailPreference'
  | 'timeOfNotification'
  | 'dayOfTheWeek'
  | 'notificationPreference'
> => {
  const { [FilterType.MORE_FILTERS]: moreFilters } = filters;
  const filtersData = transformAppliedFiltersToPostData(filters);
  const {
    autoDateRange,
    publishedSinceSeconds,
    eventStartDate,
    eventEndDate,
    keyword,
    category,
    country,
    ...parsedFilters
  } = filtersData;

  let keywords: KeywordsFilters['keywords'] | null = null;

  if (keyword) {
    keywords = (JSON.parse(fromBase64(keyword)) as KeywordsFilters).keywords;
  }

  return {
    ...parsedFilters,
    datastream: getDatastreamsFromAppliedFilters(moreFilters),
    keywords,
    publishedSinceSeconds: publishedSinceSeconds || null,
    autoDateRange: autoDateRange || null,
    eventStartDate: eventStartDate || null,
    eventEndDate: eventEndDate || null,
    categories: category,
    countries: country,
    geofences: getGeofencesFromAppliedFilters(filters[FilterType.LOCATION]),
  };
};

const getPublicationDateFromFocusData = (publishedSinceSeconds: number): AppliedFilterDataItem => {
  const predefinedOption = timeframeRadioButtonData.find(
    option => option.value === publishedSinceSeconds
  );

  if (predefinedOption) {
    return {
      label: predefinedOption.name as string,
      value: String(predefinedOption.value),
    };
  }

  const customNearestOption = [...timeframeCustomTimeData]
    .reverse()
    .find(option => option.amountInSeconds < publishedSinceSeconds);

  if (customNearestOption) {
    const customTimeFrameAmount = Math.floor(
      publishedSinceSeconds / customNearestOption.amountInSeconds
    );

    return {
      label: `${customTimeFrameAmount} ${customNearestOption.label}`,
      value: `${customTimeFrameAmount} ${customNearestOption.name}`,
    };
  }

  return {
    label: '',
    value: '',
  };
};

const getEventDateFromFocusData = ({
  autoDateRange,
  startDate,
  endDate,
}: {
  autoDateRange: AutoDateRange | null;
  startDate: string | null;
  endDate: string | null;
}): AppliedFilterDataItem | null => {
  if (autoDateRange) {
    const predefinedValue = dropdownTimeframeOptions.find(
      // @ts-expect-error `apiValue` exists in the select option, it's not required in the select option type as it's needed only to get the value for the API
      option => option.apiValue === autoDateRange
    );

    if (predefinedValue) {
      return {
        label: predefinedValue.label as string,
        value: predefinedValue.value,
      };
    }
  }

  if (startDate && endDate) {
    return {
      label: `${startDate} - ${endDate}`,
      value: `${startDate} - ${endDate}`,
    };
  }

  return null;
};

const getKeywordsFromFocusData = (
  keywords: KeywordsFilters['keywords'] | null
): AppliedFilterData => {
  if (!keywords) {
    return [];
  }

  return keywords.map(({ keyword, type }) => ({
    label: getKeywordFilterLabel(keyword, type),
    value: getKeywordFilterValue(keyword, type),
  }));
};

const getGeofencesFromFocusData = (
  geofences: Array<FocusGeofenceItem> | null
): AppliedFilterData | undefined => {
  return geofences?.map(geofence => {
    let geofenceValue: LocationGeofenceFilterItemValue | LocationPoiFilterItemValue | null = null;

    const baseProperties: BaseGEOJSONProperties = {
      id: geofence.uuid,
      color: geofence.color,
      description: geofence.description,
      icon: geofence.icon,
      name: geofence.name,
      shape: geofence.shape,
      tags: geofence.tags,
    };

    if (geofence.shape === GEOJSONShapeType.CIRCLE && geofence.polygon) {
      geofenceValue = {
        locationItemType: LocationFilterItemType.GEOFENCE_RADIAL,
        value: reverseGeoJSONCoordinates({
          type: 'Feature',
          geometry: geofence.polygon,
          properties: {
            ...baseProperties,
            shape: GEOJSONShapeType.CIRCLE,
            center: {
              lat: geofence.lat!,
              lng: geofence.long!,
            },
            address: geofence.address!,
            radius: geofence.radius!,
            radiusUnit: geofence.radiusUnit!,
          },
        }) as RadialGEOJSONData,
      } satisfies LocationGeofenceFilterRadialItemValue;
    } else if (geofence.shape === GEOJSONShapeType.POLYGON && geofence.polygon) {
      geofenceValue = {
        locationItemType: LocationFilterItemType.GEOFENCE_POLYGON,
        value: reverseGeoJSONCoordinates({
          type: 'Feature',
          geometry: geofence.polygon,
          properties: {
            ...baseProperties,
            shape: GEOJSONShapeType.POLYGON,
          },
        }) as PolygonGEOJSONData,
      } satisfies LocationGeofenceFilterItemValue;
    } else if (geofence.shape === GEOJSONShapeType.LINE && geofence.line) {
      geofenceValue = {
        locationItemType: LocationFilterItemType.GEOFENCE_LINE,
        value: reverseGeoJSONCoordinates({
          type: 'Feature',
          geometry: geofence.line,
          properties: {
            ...baseProperties,
            shape: GEOJSONShapeType.LINE,
            radius: geofence.radius!,
            radiusUnit: geofence.radiusUnit!,
          },
        }) as LineGEOJSONData,
      } satisfies LocationGeofenceFilterItemValue;
    } else if (geofence.shape === GEOJSONShapeType.POI && geofence.point) {
      geofenceValue = {
        locationItemType: LocationFilterItemType.POI,
        value: reverseGeoJSONCoordinates({
          type: 'Feature',
          geometry: geofence.point,
          properties: {
            ...baseProperties,
            shape: GEOJSONShapeType.POI,
            address: geofence.address!,
            radius: geofence.radius!,
            radiusUnit: geofence.radiusUnit!,
          },
        }) as PointOfInterestGEOJSONData,
      } satisfies LocationPoiFilterItemValue;
    }

    if (!geofenceValue) {
      throw new Error('Invalid geofence');
    }

    return {
      label: geofence.name,
      value: getStringifiedLocationFilterData(geofenceValue),
    };
  });
};

const getCountriesFromFocusData = (
  focusCountries: Array<string | number> | null,
  countries: CountriesResponse
): AppliedFilterData => {
  return (
    focusCountries
      ?.map(countryId => {
        const country = countries.find(({ id, name }) => id === countryId || name === countryId);
        const countryRegion = countries.find(
          ({ regionId, region }) => regionId === countryId || region === countryId
        );

        let filterCountryName = country?.name;
        let filterCountryId = country?.id;

        if (countryRegion) {
          filterCountryName = countryRegion.region;
          filterCountryId = countryRegion.regionId;
        }

        if (!filterCountryName || !filterCountryId) {
          return null;
        }

        return {
          label: filterCountryName,
          value: getStringifiedLocationFilterData({
            locationItemType: LocationFilterItemType.COUNTRY,
            value: {
              name: filterCountryName,
              id: filterCountryId,
            },
          } satisfies LocationCountryFilterItemValue),
        };
      })
      .filter(Boolean as unknown as ExcludesEmpty) || []
  );
};

const getCategoriesFromFocusData = (
  focusCategories: Array<string | number> | null,
  categories: CategoriesResponse
): AppliedFilterData => {
  return (
    focusCategories
      ?.map(categoryId => {
        const category = categories.find(
          ({ id, name }) => id === categoryId || decodeHtml(name) === decodeHtml(String(categoryId))
        );

        if (!category) {
          return null;
        }

        return {
          label: category.name,
          value: category.id,
        };
      })
      .filter(Boolean as unknown as ExcludesEmpty) || []
  );
};

export const transformFocusDataToAppliedFilters = ({
  focusData,
  categories,
  countries,
}: {
  focusData: FocusResponseItem;
  categories: CategoriesResponse;
  countries: CountriesResponse;
}): AppliedFilters => {
  let timeFrameFilters: AppliedFilterData = [];

  if (focusData.publishedSinceSeconds) {
    timeFrameFilters = [getPublicationDateFromFocusData(focusData.publishedSinceSeconds)];
  } else if (focusData.autoDateRange || (focusData.eventStartDate && focusData.eventEndDate)) {
    const timeframe = getEventDateFromFocusData({
      autoDateRange: focusData.autoDateRange,
      startDate: focusData.eventStartDate,
      endDate: focusData.eventEndDate,
    });

    if (timeframe) {
      timeFrameFilters = [timeframe];
    }
  }

  const keywords = getKeywordsFromFocusData(focusData.keywords);
  const geofences = getGeofencesFromFocusData(focusData.geofences);
  const countriesList = getCountriesFromFocusData(focusData.countries, countries);
  const categoriesList = getCategoriesFromFocusData(focusData.categories, categories);

  const moreFilters = [
    ...(focusData.datastream?.map(datastream => {
      if (datastream === FocusDataStream.LOOKOUT) {
        return {
          label: API_ACCESS.LOOKOUT,
          value: API_ACCESS.LOOKOUT,
        };
      }

      return {
        label: datastream,
        value: datastream,
      };
    }) || []),
    ...(focusData.impact?.map(impact => ({
      label: impact,
      value: impact,
    })) || []),
    ...(focusData.reportType?.map(reportType => ({
      label: reportType,
      value: reportType,
    })) || []),
  ];

  const filters: AppliedFilters = {};

  if (moreFilters.length) {
    filters[FilterType.MORE_FILTERS] = moreFilters;
  }

  if (keywords.length) {
    filters[FilterType.KEYWORDS] = keywords;
  }

  if (timeFrameFilters.length) {
    filters[FilterType.TIMEFRAME] = timeFrameFilters;
  }

  if (geofences?.length || countriesList.length) {
    filters[FilterType.LOCATION] = [...(geofences || []), ...countriesList];
  }

  if (categoriesList.length) {
    filters[FilterType.CATEGORY] = categoriesList;
  }

  return filters;
};
