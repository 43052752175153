import { useState, useCallback, useMemo, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { unwrapResult } from '@reduxjs/toolkit';

import { Icon } from '@/core/components/Icons';
import { Dropdown, DropdownPosition } from '@/core/components/Dropdown';
import { DeleteModal } from '@/core/components/DeleteModal';
import { Paragraph } from '@/core/components/Typography';
import { ExportGeojsonModal } from '@/core/components/ExportGeojsonModal';
import { useAppDispatch } from '@/core/store/store';
import { UserRole } from '@/core/interfaces/common';

import {
  getFoldersListSelector,
  getAllGeofeaturesListSelector,
} from '@/features/Geofeatures/store/selectors';
import { deleteGeofeature, updateGeofeaturesData } from '@/features/Geofeatures/store/actions';
import { getUserSelector } from '@/features/Auth/store';

import { createGeoJSONStringForExport, hasPermission } from '@/utils/helpers';

import { IconWrapper } from './GeofeatureMenu.styles';
import { GeofeatureMenuProps } from './GeofeatureMenu.types';

export const GeofeatureMenu = ({
  geofeatureId,
  geoJSONData,
  onGeofeatureEditClick,
}: GeofeatureMenuProps) => {
  const { colors } = useTheme();

  const dispatch = useAppDispatch();
  const useData = useSelector(getUserSelector);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const geofeaturesFoldersList = useSelector(getFoldersListSelector);
  const geofeaturesList = useSelector(getAllGeofeaturesListSelector);

  const currentGeofeature = useMemo(
    () => geofeaturesList.find(item => item.id === geofeatureId),
    [geofeatureId, geofeaturesList]
  );

  const handleGeofeatureFolderChange = useCallback(
    async (folderId: number) => {
      unwrapResult(
        await dispatch(
          updateGeofeaturesData([
            {
              id: geofeatureId,
              ...geoJSONData,
              // @ts-expect-error geoJSONData has correct properties and is corelated with update data
              properties: {
                ...geoJSONData.properties,
                folder: folderId,
              },
            },
          ])
        )
      );

      setIsMenuOpen(false);
    },
    [dispatch, geoJSONData, geofeatureId]
  );

  const canEditOthersGeofeatures = useMemo(
    () => hasPermission(useData!.groups, [UserRole.MANAGEMENT_ADMIN, UserRole.TEAM_LEADER]),
    [useData]
  );

  const geofeatureMenuItems = useMemo(() => {
    const itemsList = [];

    const geofeatureFolder = geofeaturesFoldersList.find(
      folder => folder.id === currentGeofeature?.folderId
    )!;
    const isOwnGeofeature = !geofeatureFolder?.user?.includes(useData!.id);

    if (canEditOthersGeofeatures || isOwnGeofeature) {
      itemsList.push(
        {
          label: 'Edit',
          onClick: onGeofeatureEditClick,
        },
        {
          label: 'Move to',
          nestedItems: geofeaturesFoldersList.map(folder => ({
            id: folder.id,
            label: <span>{folder.name}</span>,
            onClick: (event: MouseEvent<HTMLLIElement>) => {
              event.stopPropagation();

              handleGeofeatureFolderChange(folder.id);
            },
          })),
          maxWidth: '340px',
        }
      );
    }

    itemsList.push({
      label: 'Get GEOJSON',
      onClick: () => setIsExportModalVisible(true),
    });

    if (isOwnGeofeature) {
      itemsList.push({
        label: 'Delete',
        onClick: () => {
          setIsMenuOpen(false);
          setIsDeleteModalOpen(true);
        },
      });
    }

    return itemsList;
  }, [
    canEditOthersGeofeatures,
    currentGeofeature?.folderId,
    geofeaturesFoldersList,
    handleGeofeatureFolderChange,
    onGeofeatureEditClick,
    useData,
  ]);

  const selectedGeofeatureGeoJSONsForExport = useMemo<string>(() => {
    if (!currentGeofeature) {
      return '';
    }

    return createGeoJSONStringForExport([currentGeofeature.geoJSONData]);
  }, [currentGeofeature]);

  const handleExportModalClose = useCallback(() => {
    setIsMenuOpen(false);
    setIsExportModalVisible(false);
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    unwrapResult(await dispatch(deleteGeofeature(geofeatureId)));

    setIsDeleteModalOpen(false);
  }, [dispatch, geofeatureId]);

  return (
    <>
      <Dropdown
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        offsetY={30}
        offsetX={-50}
        position={DropdownPosition.LEFT}
        control={
          <IconWrapper>
            <Icon
              color={colors.secondaryText}
              name="Ellipsis"
              size={20}
            />
          </IconWrapper>
        }
        menuItems={geofeatureMenuItems}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteConfirm}
        title={`Delete ${name}?`}
        content={
          <Paragraph type="normal">Are you sure to delete the selected geofeature…</Paragraph>
        }
        confirmButtonText="YES, DELETE"
        onDelete={onDeleteConfirm}
      />
      <ExportGeojsonModal
        isOpen={isExportModalVisible}
        onClose={handleExportModalClose}
        geoJSON={selectedGeofeatureGeoJSONsForExport}
      />
    </>
  );
};
